'use client';
import {Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useRouter} from 'next/navigation';
import {useCookies} from 'react-cookie';
import {ACCESS_TOKEN_COOKIE} from '@/constants/cookies';

interface ErrorPageProps {
  title?: string;
  errorCode?: number | string | null;
  errorMessage?: string;
  ctaComponent?: React.ReactNode;
}

export default function ErrorPage({
  title = 'Sorry',
  errorCode = 'Oops',
  errorMessage = "We couldn't find that page",
  ctaComponent,
}: Readonly<ErrorPageProps>) {
  const [cookies] = useCookies([ACCESS_TOKEN_COOKIE]);
  const router = useRouter();

  const handleClick = () => {
    const isLogged = !!cookies[ACCESS_TOKEN_COOKIE];
    router.push(isLogged ? '/home' : location.origin);
  };

  return (
    <div className='flex-1'>
      <div
        className={clsx(
          'relative flex flex-col items-center overflow-hidden pt-9 text-center text-neutral-primary before:absolute before:left-1/2 before:top-1/2 before:z-0 before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:text-[40vw] before:font-bold before:text-slate-100 before:content-[attr(data-error)] md:pt-20 before:lg:text-[20vw]',
        )}
        data-error={errorCode}>
        <h1 className='md:[3.5rem] z-10 my-0 font-houschka-rounded text-3xl font-semibold uppercase tracking-tighter sm:text-6xl'>
          {title}
        </h1>

        <h4 className='z-10 mb-2 font-houschka-rounded text-xl sm:text-3xl'>
          {errorMessage}
        </h4>

        <div className='z-10'>
          {ctaComponent ?? (
            <Button id='back_to_home' onClick={handleClick} size='lg'>
              Back to Home
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
